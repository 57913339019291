
import { defineComponent } from "vue";
import KTNewTargetModal from "@/components/modals/forms/NewTargetModal.vue";

export default defineComponent({
  name: "projects-table",
  components: {
    KTNewTargetModal,
  },
  props: {
    widgetClasses: {
      type: String,
    },
    projects: {
      type: Object,
    },
  },
  watch: {
    projects: function () {
      this.list = Object.assign({}, this.projects);
    },
  },
  data() {
    return {
      list: {},
      statusColor: {
        active: "success",
        info: "info",
        warning: "warning",
      },
    };
  },
  methods: {},
});
